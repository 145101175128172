html {
  background: var(--background);
  --background: white;
  --background-alternative: #2e2e2e;
  --foreground: #2e2e2e;
  --foreground-alternative: #969696;
  --foreground-light: #cdcdcd;
  --footer-background: #1f1f1f;
  --footer-foreground: #969696;
  --accent: #f3b623;
  --positive: #63c700;
  --negative: #f80606;
  --shadow: rgba(0,0,0,0.2);
  --box-background: #fdfdfd;
}

html[data-theme=dark] {
  background: var(--background);
  --background: #0a0a0a;
  --background-alternative: #2e2e2e;
  --foreground: #f9f9f9;
  --foreground-alternative: #969696;
  --foreground-light: #6b6b6b;
  --footer-background: #d1d1d1;
  --footer-foreground: #2e2e2e;
  --accent: #f3b623;
  --positive: #7cfc00;
  --negative: #f80606;
  --shadow: rgba(255,255,255,0.1);
  --box-background: #0a0a0a;
  --mode-filter: invert(96%);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
}

body {
  font-size: 22px;
  color: var(--foreground);
  font-family: "Raleway", sans-serif;
  overflow-x: hidden;
}

h1 {
  font-family: "Manrope", sans-serif;
  font-size: 2.6em;
  margin-top: 0;
  font-weight: 300;
}

h2 {
  font-weight: 300;
  font-size: 2em;
  color: var(--accent);
}

h3 {
  font-size: 1.6em;
  font-weight: 600;
}

ul {
  list-style-type: square;
}

a {
  color: var(--foreground);
  position: relative;
  text-decoration: underline;
}

a:hover {
  color: var(--accent);
  text-decoration: none;
}

.accent {
  position: relative;
}

.accent:before {
  position: absolute;
  content: "";
  height: 0.2em;
  bottom: 0.16em;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  /* background: RGBA(243, 182, 35,1);*/
  background: var(--accent);
}

.loading-text {
  color: var(--foreground);
  text-align: center;
  margin-top: 3em;
}

.mode-aware {
  filter: var(--mode-filter);
}

.hash-box {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "hash-box-left" "hash-box-middle" "hash-box-right";
  border: 1px solid red;
}

.hash-box-left {
  grid-area: hash-box-left;
  border: 1px solid green;
}

.hash-box-middle {
  grid-area: hash-box-middle;
  border: 1px solid yellow;
  max-width: 100px;
}

.hash-box-right {
  grid-area: hash-box-right;
}

.hash-box div {
  display: contents;
}

.app {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "app-header" "app-content" "app-footer";
}

.app-header {
  grid-area: app-header;
  z-index: 10;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "header nav";
}

.margin {
  margin: 1.4em;
}

.margin-not-bottom {
  margin: 1.4em 1.4em 0 1.4em;
}

.app-header-background {
  grid-area: app-header;
  background-image: url("/assets/top-background.png");
  background-repeat: repeat-x;
  background-position: bottom;
  filter: var(--mode-filter);
  z-index: 5;
}

header {
  grid-area: header;
  align-self: center;
  display: flex;
  align-items: center;
}

header a {
  text-decoration: none;
}

header a:hover {
  color: var(--foreground);
  text-decoration: none;
}

.header-logo {
  grid-area: header-logo;
  display: inline-block;
}

.shadow {
  border: 1px solid var(--foreground-light);
  background-color: var(--box-background);
  border-radius: 0.6em;
}

.box {
  border: 1px solid var(--foreground-light);
  margin-bottom: 1.4em;
  padding: 0 1em 1em 1em;
  background-color: var(--box-background);
  border-radius: 0.6em;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.chains {
  background-color: var(--box-background);
  border-radius: 6px;
  max-width: 400px;
  border: 1px solid var(--foreground);
  padding: 1em;
  border-radius: 10px;
  top: 1em;
  left: 5em;
  grid-area: nav;
  align-self: center;
  justify-self: end;
  font-size: 1.2em;
  box-shadow: 0px 8px 16px 0px var(--shadow);
  z-index: 1;
}

.chains img {
  width: 32px;
  height: 32px;
}

.chains ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.chains li {
  float: left;
  margin-left: 0.5em;
  width: 180px;
}

.chain-item {
  display: flex;
  margin-bottom: 1em;
}

.chain-link {
  height: 32px;
  line-height: 32px;
}

.chain-link img {
  margin-right: 0.2em;
}

.chain-link:hover {
  text-decoration: underline;
}

.decimals {
  color: var(--foreground-alternative);
}

.symbol {
  margin-left: 0.3em;
}

.header-title {
  grid-area: header-title;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  font-size: 1.2em;
}

.header-image {
  width: 4em;
  padding-right: 0.8em;
}

nav.menu {
  grid-area: nav;
  align-self: center;
  justify-self: end;
  font-size: 1.2em;
}

nav.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav.menu li {
  float: left;
  margin-left: 0.5em;
}

.app-content {
  grid-area: app-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content";
}

.app-footer-background {
  grid-area: app-footer;
  z-index: 5;
  background-color: var(--footer-background);
  background-image: url("/assets/footer-background.png");
  background-repeat: repeat;
  filter: var(--mode-filter);
}

.app-footer {
  grid-area: app-footer;
  z-index: 10;
  color: var(--footer-foreground);
}

footer {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 0 0.8em;
  grid-template-areas: "footer-left footer-right footer-toggle";
}

.footer-left {
  z-index: 10;
  grid-area: footer-left;
  align-self: center;
}

.footer-right {
  grid-area: footer-right;
  align-self: center;
}

.footer-toggle {
  font-size: 1em;
  grid-area: footer-toggle;
  align-self: center;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content {
  grid-area: content;
}

.content-limited {
  grid-area: content;
  max-width: 1024px;
}

.content-center {
  grid-area: content;
  max-width: 1024px;
  align-self: center;
  justify-self: center;
}

.content-centered {
  grid-area: content;
  max-width: 1024px;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.content-centered-top {
  grid-area: content;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}

.icon-links {
  font-size: 2.4em;
}

.icon-links a {
  margin-right: 0.2em;
  color: var(--footer-foreground);
}

.icon-links a:hover {
  color: var(--accent);
}

.block-details-toggle {
  float: right;
  margin-top: 2em;
  margin-right: 1em;
}

.link {
  text-decoration: none;
  cursor: pointer;
}

.link:before {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--foreground);
}

.link:hover:before {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--accent);
}

.nav-link {
  text-decoration: none;
  color: var(--foreground);
}

.nav-link:hover {
  color: var(--foreground);
}

.nav-link:hover:before {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--foreground);
}

.link-active:before {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--foreground);
}

.smaller {
  font-size: 0.8em;
}

.sponsor-widget a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsor-widget img {
  width: 200px;
  border-radius: 50%;
  border: 5px solid transparent;
  box-shadow: 0px 0px 0px 2px var(--accent);
  transition: box-shadow 0.25s linear;
  margin: 0.5em;
}

.sponsor-widget img:hover {
  box-shadow: 0px 0px 0px 6px var(--accent);
  transition: box-shadow 0.25s linear;
}

.amount-button {
  border: 0;
  background-color: transparent;
  font-size: 1.4em;
  color: var(--footer-foreground);
}

.amount-button:hover {
  border: 0;
  background-color: transparent;
  font-size: 1.4em;
  color: var(--accent);
  cursor: pointer;
}

.mode-toggle {
  display: flex;
  flex-direction: row-reverse;
}

.mode-toggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.mode-toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 55px;
  height: 30px;
  background: var(--foreground-alternative);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: relative;
}

.mode-toggle label:after {
  content: "";
  background: #fff;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 4px;
  transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
}

.mode-toggle input:checked + label {
  background: var(--footer-foreground);
}

.mode-toggle input:checked + label:after {
  left: calc(100% - 5px);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.muted {
  color: var(--foreground-alternative);
}

.centered {
  text-align: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--foreground);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.explorer-h2 {
  margin: 0 0 -0.25em 0;
  padding: 0;
}

.explorer-h1 {
  margin: 0 0 0.4em 0;
  padding: 0;
}

.input-search {
  color: var(--foreground);
  font-size: 1.2em;
  padding: 0.6em 0.4em 0.6em 2em;
  width: 100%;
  font-family: "Manrope", sans-serif;
  font-family: "Raleway", sans-serif;
}

.search-global {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  padding-left: 3em;
  padding-right: 3em;
}

.input-search-global {
  color: var(--foreground);
  font-size: 1.2em;
  padding: 0.6em 0.4em 0.6em 2em;
  width: 80%;
  font-family: "Raleway", sans-serif;
  flex-grow: 2;
  padding: 0.6em 0.4em 0.6em 2em;
  margin-bottom: 0;
  margin-right: 0.3em;
}

.input-search-global-select {
  background-color: var(--box-background);
  color: var(--foreground-alternative);
  width: 180px;
  font-family: "Raleway", sans-serif;
  border: 1px solid var(--foreground-alternative);
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-size: 1.3em;
  height: 100%;
  border-radius: 0.6em;
}

.input-icon {
  float: right;
  margin-top: 0.7em;
  margin-left: 0.5em;
  font-size: 1.2em;
  position: absolute;
  color: var(--foreground-alternative);
}

.grid,
.grid-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.25em 1.2em;
}

.grid div {
  display: contents;
}

.grid a {
  color: var(--accent);
  text-decoration: none;
}

.grid a:hover {
  text-decoration: underline;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.grid-odd-line div:nth-child(even) {
  color: var(--foreground-alternative);
}

.grid-header {
  font-weight: bolder;
}

.grid-item:nth-of-type(odd) div span:nth-child(2) {
  text-align: left;
}

.grid-hash {
  font-size: 0.8em;
  padding-top: 1em;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  gap: 0 0.4em;
  align-items: center;
}

.grid-hash > div {
  display: contents;
}

.grid-hash-middle {
  font-family: Roboto Mono, monospace;
  font-weight: 700;
}

.number {
  font-size: 0.9em;
  font-family: Roboto Mono, monospace;
}

.grid-hash-block-index {
  font-size: 0.8em;
}

.grid-hash-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 0.2em;
}

.address-transactions-scrollable {
  min-height: 100px !important;
  max-height: 230px !important;
}

.grid-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 1em;
  grid-template-areas: "grid-double-left grid-double-right";
}

.grid-double-left {
  grid-area: grid-double-left;
}

.grid-double-right {
  grid-area: grid-double-right;
}

.grid-list-2,
.grid-list-3,
.grid-list-4 {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
}

.grid-list-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-list-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-list-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-list-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-list-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-list-inputs,
.grid-list-outputs {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
}

.grid-list-inputs {
  grid-template-columns: 18px 18px minmax(0, 1fr) auto;
}

.grid-list-outputs {
  grid-template-columns: 18px minmax(0, 1fr) auto 18px;
}

.grid-list-transactions {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: auto minmax(0, 1fr) 140px auto;
}

.grid-list-contract-address {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: 200px 100px 250px 100px minmax(0, 3fr) auto;
}

.grid-list-contract-token {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: auto auto;
}

.grid-list-contract-nft {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.6em;
  grid-template-columns: 100%;
}

.grid-list-contract-list {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: 250px 140px auto;
}

.grid-list-contract-listbytype {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: 250px auto 140px auto;
}

.grid-list-contract-dao-proposals {
  font-size: 0.75em;
  margin: 1em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: 450px 250px auto auto;
}

.grid-list-configure-api {
  font-size: 0.95em;
  margin: 0.9em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: auto;
}

.grid-list-configure-api-inner {
  font-size: 0.85em;
  margin: 0.9em;
  display: grid;
  gap: 0.3em;
  grid-template-columns: auto auto;
}

.dimmed-notification {
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 1em;
}

.address {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

pre {
  max-width: 100%;
  overflow: hidden;
}

.grid-label-value {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.25em 1.2em;
}

.grid-label-value div {
  display: contents;
}

.grid-label-value div span:nth-child(1) {
  color: var(--foreground-alternative);
  text-align: right;
}

.grid-label-value div span:nth-child(1)::after {
  content: ":";
}

.grid-label-value div span:nth-child(2) {
  text-align: right;
}

.grid-label-value ul {
  margin: 0;
}

.grid-label-value-label {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.25em 1.2em 1.2em;
}

.grid-label-value-label div {
  display: contents;
}

.grid-label-value-label div span:nth-child(1) {
  color: var(--foreground-alternative);
  text-align: right;
}

.grid-label-value-label div span:nth-child(1)::after {
  content: ":";
}

.grid-label-value-label div span:nth-child(2) {
  text-align: right;
}

.grid-label-value-label div span:nth-child(3) {
  text-align: left;
  margin-left: 1em;
}

.grid-label-value-label ul {
  margin: 0;
}

.grid-odd-color {
  border: 4px solid yellow !important;
}

.grid-item {
  display: inline-block;
}

.grid-item:nth-of-type(odd) {
  border: 4px solid green !important;
  background-color: red !important;
}

.grid-item div:nth-of-type(odd) {
  border: 4px solid green !important;
  background-color: blue !important;
}

.grid-item:nth-of-type(odd) {
  border: 4px solid green !important;
  background-color: yellow !important;
}

.grid-odd-color:nth-child(odd) div {
  background-color: red !important;
  border: 1px solid blue;
}

.breakable {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  width: 100%;
  display: inline-block;
}

.nonbreakable {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollable {
  display: block !important;
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.4em;
}

.clickable {
  cursor: pointer;
}

.error {
  color: var(--negative);
}

.error-details {
  border: 1px solid var(--foreground);
  margin: 1em;
  padding: 1em;
  text-align: left;
  font-size: 0.8em;
  max-height: 200px;
  overflow: scroll;
  box-shadow: 0px 8px 16px 0px var(--shadow);
}

.error-details div {
  margin-bottom: 1em;
}

.error-details-stack {
  font-family: Roboto Mono, monospace;
  white-space: pre;
  font-size: 0.6em;
}

.widget {
  font-size: 1.6em;
}

.widget h1,
.widget h2,
.widget h3 {
  margin: 0;
  padding: 0;
}

.widget a {
  margin: 0.2em;
}

.widget h2 span {
  font-size: 0.5em;
  margin-left: 0.4em;
  position: absolute;
}

.positive {
  color: var(--positive);
}

.negative {
  color: var(--negative);
}

.neutral {
  color: var(--foreground);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.show-small {
  display: none;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@media only screen and (max-width: 140em) {
  .content-centered-top {
    margin-left: 16%;
    margin-right: 16%;
  }
}
@media only screen and (max-width: 120em) {
  .content-centered-top {
    margin-left: 14%;
    margin-right: 14%;
  }
}
@media only screen and (max-width: 100em) {
  .content-centered-top {
    margin-left: 10%;
    margin-right: 10%;
  }
  .nonbreakable {
    width: 400px;
  }
  .search-global {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media only screen and (max-width: 70em) {
  body {
    font-size: 20px;
  }
  .content-centered-top {
    margin-left: 8%;
    margin-right: 8%;
  }
  .nonbreakable {
    width: 300px;
  }
}
@media only screen and (max-width: 60em) {
  body {
    font-size: 18px;
  }
  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.2em;
  }
  .content-centered-top {
    margin-left: 6%;
    margin-right: 6%;
  }
  .nonbreakable {
    width: 250px;
  }
}
@media only screen and (max-width: 50em) {
  body {
    font-size: 18px;
  }
  .margin {
    margin: 0.6em;
  }
  nav.menu li {
    float: none;
    margin-bottom: 0.3em;
  }
  .hide-small {
    display: none;
  }
  .show-small {
    display: inline-block;
  }
  .small-little {
    font-size: 0.7em;
  }
  .content-centered-top {
    margin-left: 0%;
    margin-right: 0%;
  }
  .nonbreakable {
    width: 150px;
  }
  .grid-list-transactions {
    grid-template-columns: none;
  }
}
@media only screen and (max-width: 22em) {
  body {
    font-size: 18px;
  }
  .margin {
    margin: 0.6em;
  }
  .nonbreakable {
    width: 100px;
  }
}
html {
  background: var(--background);
  --background: white;
  --background-alternative: #2e2e2e;
  --foreground: #2e2e2e;
  --foreground-alternative: #969696;
  --foreground-light: #cdcdcd;
  --footer-background: #1f1f1f;
  --footer-foreground: #969696;
  --accent: #f3b623;
  --positive: #63c700;
  --negative: #f80606;
  --shadow: rgba(0,0,0,0.2);
  --box-background: #fdfdfd;
}

html[data-theme=dark] {
  background: var(--background);
  --background: #0a0a0a;
  --background-alternative: #2e2e2e;
  --foreground: #f9f9f9;
  --foreground-alternative: #969696;
  --foreground-light: #6b6b6b;
  --footer-background: #d1d1d1;
  --footer-foreground: #2e2e2e;
  --accent: #f3b623;
  --positive: #7cfc00;
  --negative: #f80606;
  --shadow: rgba(255,255,255,0.1);
  --box-background: #0a0a0a;
  --mode-filter: invert(96%);
}

.tippy-box[data-theme~=tomato] {
  background-color: tomato;
  color: yellow;
}

.tooltip {
  margin: 0.2em;
  font-size: 1.2em;
}

.listing-table {
  border: 0;
  width: 100%;
  text-align: left;
}

.details-box {
  grid-column: 1/span 4;
  padding: 0.2em;
  border: 1px solid var(--foreground-light);
  background-color: var(--box-background);
}